import React, { useEffect, useState } from 'react';
import 'App.css';
import Container from 'react-bootstrap/Container';
import InstructionsModal from '../InstructionsModal';
import Row from 'react-bootstrap/Row';
import { Route, Routes, Link } from 'react-router-dom';
import UnitCostCategory from './UnitCostCategory';

function UnitCostAlternatives(props) {

  const setTitle = props.setTitle
  const [showModal, setShowModal] = useState(false);

  const [UnitCostCategories, setUnitCostCategories] = useState([]);

  useEffect(() => {
    fetch('/unit-costs-data/UnitCostCategories.json')
      .then(response => response.json())
      .then(json => setUnitCostCategories(json))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    setTitle("Discover alternative choices with unit costs.");
  }, [setTitle]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container className='unit-costs-apps'>
      <h1 className="calc-header">Unit Cost Alternatives</h1>
      <InstructionsModal handleCloseModal={handleCloseModal} handleOpenModal={handleOpenModal} showModal={showModal}>
        <p>When you have some money to spend on a category of purchase, it might be interesting to think what else you could buy for that money.</p>
        <p>The example I like to use is 1 theme park ticket could get you 6 - 7 cinema tickets or 6 months worth of a streaming media service.</p>
      </InstructionsModal>
      <Row>
        <ul className='horizontal-submenu'>
          {UnitCostCategories.map((category) => (
            <li key={category.name}><Link to={category.name} state={{ title: "value" }}>{category.title}</Link></li>
          ))}
        </ul>
      </Row>
      <Row>
        <Routes>
          {UnitCostCategories.map((category) => (
            <Route key={category.name} path={category.name} element={<UnitCostCategory setTitle={props.setTitle} dataPath={category.name} title={category.title} />} />
          ))}
        </Routes>
      </Row>
    </Container>
  );
};

export default UnitCostAlternatives;
