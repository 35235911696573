import { React, useState, useEffect } from 'react';
import InputLabelGroup from 'InputLabelGroup';
import CheckboxLabelGroup from 'CheckBoxLabelGroup';
import Form from 'react-bootstrap/Form';

function Hotel(props) {

  const [hotelCost, setHotelCost] = useState(120);
  const [breakfast, setBreakfast] = useState(18);
  const [breakfastGroceriesCost, setBreakfastGroceriesCost] = useState(25);
  const [lunch, setLunch] = useState(20);
  const [dinner, setDinner] = useState(25);
  const [breakfastAlternative, setBreakfastAlternative] = useState(false);

  const [totalMealsPerDay, setTotalMealsPerDay] = useState(0);
  const [allMealsOverStay, setAllMealsOverStay] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const handleHotelCostChange = (event) => {
    setHotelCost(event.target.value);
  };

  const handleBreakfastChange = (event) => {
    setBreakfast(event.target.value);
  };

  const handleHotelBreakfastGroceriesChange = (event) => {
    setBreakfastGroceriesCost(event.target.value);
  };

  const handleBreakfastAlternativeChange = (event) => {
    setBreakfastAlternative(event.target.checked);
  };

  const handleLunchChange = (event) => {
    setLunch(event.target.value);
  };

  const handleDinnerChange = (event) => {
    setDinner(event.target.value);
  };

  useEffect(() => {
    const newTotalMealsPerDay = (breakfastAlternative === false ? Number(breakfast) || 0 : 0) + (Number(lunch) || 0) + (Number(dinner) || 0);
    const newAllMealsOverStay = newTotalMealsPerDay * props.numberOfPeople * props.nights;
    setTotalMealsPerDay(newTotalMealsPerDay);
    setAllMealsOverStay(newAllMealsOverStay + (breakfastAlternative ? Number(breakfastGroceriesCost) || 0 : 0));

    setGrandTotal(newAllMealsOverStay + Number(hotelCost) * props.nights);
    props.onGrandTotalChange(grandTotal)
  }, [breakfast, lunch, dinner, hotelCost, props, breakfastAlternative, breakfastGroceriesCost, grandTotal]);

  return (
    <Form>
      <InputLabelGroup label="Room per night" type="text" controlId="hotelAmount" onChange={handleHotelCostChange} value={hotelCost} />
      <p>Total Hotel Cost: {(Number(hotelCost) * props.nights).toLocaleString({ style: 'currency' })}</p>
      <fieldset>
        <legend>Meals (per person/per day)</legend>
        <CheckboxLabelGroup
          label="Have breakfast in - buy cereal once instead."
          type="checkbox"
          controlId="breakfastAlternative"
          title="You could save money by bring travel cutlery and a bowl and just buying a small packet of cereal and milk"
          onChange={handleBreakfastAlternativeChange}
          value={breakfastAlternative} />
        {!breakfastAlternative &&
          <InputLabelGroup
            label="Average Breakfast Cost"
            type="text"
            title="Think about the cost of breakfasts in a cafe or if you just like to get a takeaway sandwich instead. Include coffee or juice."
            controlId="breakfastCost"
            onChange={handleBreakfastChange}
            value={breakfast} />}
        {breakfastAlternative &&
          <InputLabelGroup
            label="Breakfast Groceries Cost"
            type="text"
            title="Think about the cost of groceries for breakfasts. Include coffee or juice."
            controlId="breakfastGroceriesCost" onChange={handleHotelBreakfastGroceriesChange} value={breakfastGroceriesCost} />}
        <InputLabelGroup label="Average Lunch Cost" type="text" controlId="lunchCost" title="Think about the cost of lunch out or food court meals if you like." onChange={handleLunchChange} value={lunch} />
        <InputLabelGroup label="Average Dinner Cost" type="text" controlId="dinnerCost" title="Think about the cost of dinner out or food court meals if you like." onChange={handleDinnerChange} value={dinner} />
      </fieldset>
      <p>Total Meals For Everyone Over Stay: {allMealsOverStay.toLocaleString({ style: 'currency' })} ({totalMealsPerDay.toLocaleString({ style: 'currency' })} X {props.numberOfPeople} people X {props.nights} nights) {breakfastAlternative ? `+ ${breakfastGroceriesCost} for brekkie food` : ''}</p>
      <div>Total Cost: {grandTotal} ({hotelCost * props.nights} + {allMealsOverStay.toLocaleString({ style: 'currency' })})</div>
    </Form>
  )
}

export default Hotel;