import React from 'react';

function InputLabelGroup(props) {

  return (
    <div className={`grand-total ${props.best === true ? 'best' : ''} `}>
      <span>{props.total}</span>
    </div>
  )
}

export default InputLabelGroup;