import { React, useState, useEffect } from 'react';
import CategoryEmoji from './CategoryEmoji';
import {AUdollar} from '../utils/formats';

function ItemRow(props) {
    const {item, spend, setThisSubtotalKeyValue} = props;
    const [itemTotal, setItemTotal] = useState(0);
    const [itemPeriodTotal, setItemPeriodTotal] = useState(0);
    const [chosenItem, setChosenItem] = useState('');
    const [chosenItemCost, setChosenItemCost] = useState(0);
    const [itemPeriod, setItemPeriod] = useState(item.budgetPeriod);
    const [chosenQty, setChosenQty] = useState(0);
    
    const setSelection = (cost, itemPeriod) => {
        return () => {
            setChosenItem(cost.costType);
            setChosenItemCost(cost.cost);
            setItemPeriod(itemPeriod ?? cost.budgetPeriod)
        }
    }
    const handleQty = (event) => {
        setChosenQty(parseInt(event.target.value));
    }
    
    useEffect(() => {
        let factor = 1
        if (itemPeriod === "quarter") {factor = 0.3333333}
        if (itemPeriod === "week") {factor = 4.25}
        if (itemPeriod === "fortnight") {factor = 2}
        if (itemPeriod === "year") {factor = 0.0833333333333}
        const thisTotal = chosenQty * (factor * chosenItemCost)
        const thisPeriodTotal = chosenQty * chosenItemCost
        setItemTotal(thisTotal);
        setItemPeriodTotal(thisPeriodTotal);
        const thisSub = {Item: props.item.name, Cost: thisTotal}
        setThisSubtotalKeyValue(thisSub)
    }, [chosenQty, chosenItemCost, props.item.name, setThisSubtotalKeyValue, item.budgetPeriod, itemPeriod])

    return (
        <>
            <td>
                <CategoryEmoji category={item.emoji} />
            </td>
            <td className='item-title'>{item.budgetPeriod && <>{item.budgetPeriod}ly</>} {item.nounForEach} {item.name}:
                <ul>
                    {item.costs.map((cost) => (
                        Math.floor(spend / cost.cost) > 0 || 
                        props.planningMode || 
                        (item.budgetPeriod === "quarter" && Math.floor(spend / (cost.cost / 3))) > 0 || 
                        (cost.budgetPeriod === "year" && Math.floor(spend / (cost.cost / 12) > 0)) ?
                            <li key={item.name + cost.costType}>
                                <input type='radio' id={item.name + cost.costType} name={item.name} value={cost.costType} onChange={setSelection(cost, item.budgetPeriod)}/>
                                <label htmlFor={item.name + cost.costType}>{`${cost.costType} at ${AUdollar.format(cost.cost)} each`}</label></li>
                            :
                            <li key={item.name + cost.costType}>{`You need to save ${AUdollar.format(spend)} at least ${Math.ceil(cost.cost / spend)} times to buy 1 ${cost.costType} here. (${AUdollar.format(cost.cost)})`}</li>
                    ))}
                </ul></td>
            <td className='qtyChooser'>
                <span className='cost-type'>{chosenItem}</span> X <input type='number' min={0} disabled={chosenItem === ''} onChange={handleQty}/> = <span className='numbers'>{AUdollar.format(itemPeriodTotal)}</span>{!props.planningMode && <span> each {itemPeriod}</span>}
                </td>
                {!props.planningMode && <td className='monthlyTotal'>= {AUdollar.format(itemTotal)} each month</td>}
        </>
    )
}

export default ItemRow;