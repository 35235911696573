import React from 'react';
import InputLabelGroup from 'InputLabelGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BaseParameters extends React.Component {

    render() {
        return (
            <Form>
                <Row>
                    <Col><InputLabelGroup label="How Many Nights will you stay?" type="number" controlId="lengthOfCalc" onChange={this.props.changeLength} value={this.props.nights} /></Col>
                    <Col><InputLabelGroup label="How many adults" type="number" controlId="adults" value={this.props.adults} onChange={this.props.changeAdults} /></Col>
                </Row>
            </Form>
        );
    }
}

export default BaseParameters;