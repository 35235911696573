import React from 'react';
import InputLabelGroup from 'InputLabelGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class BaseParameters extends React.Component {

    render() {
        return (
            <Form>
                <Row>
                    <Col><InputLabelGroup label="Length of Base Scenario" type="number" controlId="lengthOfCalc" onChange={this.props.changeLength} value={this.props.years} /></Col>
                    <Col><InputLabelGroup label="Your Age" type="number" controlId="age" value={this.props.age} onChange={this.props.changeAge} /></Col>
                    <Col>
                        <InputLabelGroup label="Life Expectancy" type="number" controlId="lifeExpectancy" value={this.props.lifeExpectancy} onChange={this.props.changeLifeExpectancy} />
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default BaseParameters;