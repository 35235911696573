import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function InputLabelGroup(props) {

  return (
    <Form.Group controlId={props.controlId}>
      <Form.Label>{props.label}</Form.Label>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={props.title ? <Tooltip id={`tooltip-bottom`}>{props.title}</Tooltip> : <></>}
        trigger={'focus'}
      >
        <Form.Control type={props.type} data-toggle="tooltip" title={props.title} defaultValue={props.defaultValue} value={props.value} onChange={props.onChange} />
      </OverlayTrigger>

    </Form.Group>
  )
}

export default InputLabelGroup;