import React, { useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import ItemRow from './ItemRow';
import { AUdollar } from '../utils/formats';

export function BudgetTable(props) {
    const data = props.data;
    const spend = props.spend;

    const [total, setTotal] = useState(0)
    const [subTotalKeyValues, setSubtotalKeyValues] = useState(new Map());
    const [isOverSpending, setIsOverspending] = useState(false);

    // Refresh on change dataset
    useEffect(() => {
        setSubtotalKeyValues(new Map(data.map(item => [item.name, 0])))
    }, [data])

    // Receive updated row and update main totals array
    const handleUpdateRow = useCallback(updatedSubTotal => {
        setSubtotalKeyValues(prevSubTotalKeyValues => {
            const newList = new Map(prevSubTotalKeyValues);
            newList.set(updatedSubTotal.Item, updatedSubTotal.Cost);
            return newList;
        });
    }, [])

    // Do the calculation
    useEffect(() => {
        let sumValues = 0;
        for (const [, value] of subTotalKeyValues) {
            sumValues += value;
        }
        setTotal(sumValues);
        setIsOverspending(!props.planningMode && sumValues > spend)
    }, [subTotalKeyValues, props.planningMode, spend])

    return (
        <Row className='unit-cost-answers'>
            <table className='styled-table' id='unit-cost-budget-table'>
                <colgroup>
                    <col span="1" style={{ width: "6em" }} />
                    <col span="1" />
                    <col span="1" style={{ width: "15em", textAlign: "right" }} />
                </colgroup>
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Items</th>
                        <th>Budgeting {!props.planningMode && <><br /> (Remaining: <span className={isOverSpending ? 'warning-header numbers' : 'numbers'}>{AUdollar.format(spend - total)})</span></>}</th>
                        {!props.planningMode && <th className='monthlyTotal'>Monthly Total</th>}
                    </tr></thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.name} className='parentListItem'>
                            <ItemRow item={item} spend={spend} setThisSubtotalKeyValue={handleUpdateRow} planningMode={props.planningMode} />
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        <td></td>
                        {!props.planningMode && <td></td>}
                        <td style={{ textAlign: "right" }}>Total {!props.planningMode && 'Monthly'} Spend <br />(or allocation): <span className={isOverSpending ? 'warning numbers' : 'numbers'}> {AUdollar.format(total)}</span></td></tr></tfoot>
            </table>
        </Row>
    );
}
