import React from 'react';
import Row from 'react-bootstrap/Row';
import CategoryEmoji from './CategoryEmoji';

export function UnitCostAnswers(props) {
    const data = props.data;
    const spend = props.spend;
    const spendMinus20pc = spend * 0.8

    const AUdollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
    });

    const scenario = (item, spendInScenario) => {
        return (<>
            You can have a {item.nounForEach} {item.name} picking one of:
            <ul>
                {item.costs.map((cost) => (
                    Math.floor(spendInScenario / cost.cost) > 0 ?
                        <li key={item.name + cost.costType}>{`${Math.floor(spendInScenario / cost.cost)}X ${cost.costType} at ${AUdollar.format(cost.cost)} each - leaving ${AUdollar.format(spendInScenario - (Math.floor(spendInScenario / cost.cost) * cost.cost))} for ${item.leftOverUse} (in total)`}</li>
                        :
                        <li key={item.name + cost.costType}>{`You need to save ${AUdollar.format(spendInScenario)} at least ${Math.ceil(cost.cost / spendInScenario)} times to buy 1 ${cost.costType} at ${item.name} (${AUdollar.format(cost.cost)})`}</li>
                ))}
            </ul>
        </>)
    }

    const scenarioTwentyPC = (item) => {
        const spendInScenario = spendMinus20pc
        const keepFirst = spend - spendMinus20pc
        return (<>
            You can have a {item.nounForEach} {item.name} picking one of:
            <ul>
                {item.costs.map((cost) => (
                    Math.floor(spendInScenario / cost.cost) > 0 ?
                        <li key={item.name + cost.costType}>{`${Math.floor(spendInScenario / cost.cost)}X ${cost.costType} at ${AUdollar.format(cost.cost)} each - leaving ${AUdollar.format(spendInScenario - (Math.floor(spendInScenario / cost.cost) * cost.cost) + keepFirst)} for ${item.leftOverUse} (in total)`}</li>
                        :
                        <li key={item.name + cost.costType}>{`You need to save ${AUdollar.format(spendInScenario)} at least ${Math.ceil(cost.cost / spendInScenario)} times to buy 1 ${cost.costType} at ${item.name} (${AUdollar.format(cost.cost)})`}</li>
                ))}
            </ul>
        </>)
    }

    const dataRow = (item) => {
        return (
            <>
                <td><CategoryEmoji category={item.emoji} /></td>
                <td>{scenario(item, spend)}
                </td>
                <td>{scenarioTwentyPC(item)}
                </td>
            </>
        )
    }

    return (
        <Row className='unit-cost-answers'>
            <table className='styled-table'>
                <thead><tr><th>Category</th><th>Spending All Of It</th><th>{`Keep 20% For Other Things (Spending ${AUdollar.format(spendMinus20pc)})`}</th></tr></thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.name} className='parentListItem'>
                            {dataRow(item)}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Row>
    );
}
