import { React, useEffect, useState } from 'react';
import InputLabelGroup from 'InputLabelGroup';
import CheckboxLabelGroup from 'CheckBoxLabelGroup';
import Form from 'react-bootstrap/Form';

function Apartment(props) {

  const [apartmentCost, setApartmentCost] = useState(200);
  const [breakfastGroceries, setBreakfastGroceries] = useState(18);
  const [lunchGroceries, setLunchGroceries] = useState(25);
  const [dinnerGroceries, setDinnerGroceries] = useState(30);
  const [totalGroceries, setTotalGroceries] = useState(0);
  const [totalApartment, setTotalApartment] = useState(0);
  const [lunchAlternative, setLunchAlternative] = useState(false);
  const [lunchOut, setLunchOutCost] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const handleApartmentCostChange = (event) => {
    setApartmentCost(event.target.value);
  };

  const handleBreakfastGroceriesChange = (event) => {
    setBreakfastGroceries(event.target.value);
  };

  const handleLunchGroceriesChange = (event) => {
    setLunchGroceries(event.target.value);
  };

  const handleLunchOutChange = (event) => {
    setLunchOutCost(event.target.value);
  };

  const handleLunchAlternativeChange = (event) => {
    setLunchAlternative(event.target.checked);
  };

  const handleDinnerGroceriesChange = (event) => {
    setDinnerGroceries(event.target.value);
  };

  useEffect(() => {
    const totalGroceriesLocal = (!lunchAlternative ? Number(breakfastGroceries) || 0 : 0) + (Number(lunchGroceries) || 0) + (Number(dinnerGroceries) || 0);
    setTotalGroceries(totalGroceriesLocal);
    const lunchAltTotal = lunchAlternative ? lunchOut * props.nights : 0;
    const totalApartmentLocal = apartmentCost * props.nights
    setTotalApartment(totalApartmentLocal);
    setGrandTotal(totalApartmentLocal + totalGroceriesLocal + lunchAltTotal)
    props.onGrandTotalChange(grandTotal);
  }, [apartmentCost, breakfastGroceries, lunchGroceries, dinnerGroceries, props, grandTotal, lunchAlternative, lunchOut])

  return (
    <Form>
      <InputLabelGroup label="Room per night" type="text" controlId="rentAmount" onChange={handleApartmentCostChange} value={apartmentCost} />
      <p>Total Apartment Cost: {totalApartment.toLocaleString({ style: 'currency' })}</p>
      <fieldset>
        <legend>Groceries for stay</legend>
        <InputLabelGroup label="Likely Breakfast Cost" title="Think about what you normally eat for breakfast (cereal, eggs, tofu, etc), include drinks like juice or coffee. Remember to account for nights and people." type="text" controlId="breakfastCost" onChange={handleBreakfastGroceriesChange} value={breakfastGroceries} />
        <CheckboxLabelGroup
          label="Have lunch out - grab something while sightseeing."
          type="checkbox"
          controlId="lunchAlternative"
          title="You might want to get something for lunch while sightseeing because it's impractical to come back the hotel for lunch or carry a sandwich."
          onChange={handleLunchAlternativeChange}
          value={lunchAlternative} />
        {lunchAlternative &&
          <InputLabelGroup
            label="Average Lunch Out Cost"
            type="text"
            title="Think about the cost of lunch out or food court meals if you like."
            controlId="lunchOutAlternativeCost" onChange={handleLunchOutChange} value={lunchOut} />}

        {!lunchAlternative && <InputLabelGroup label="Likely Lunch Cost" type="text" title="Think about lunch ingredients like sandwiches or soup or rice or whatever you like to eat for lunch." controlId="lunchCost" onChange={handleLunchGroceriesChange} value={lunchGroceries} />}
        <InputLabelGroup label="Likely Dinner Cost" type="text" controlId="dinnerCost" title="Think about what you might like to cook for dinner - a stir-fry, rice, veggies, or salad." onChange={handleDinnerGroceriesChange} value={dinnerGroceries} />
        <div>Likely total groceries for stay: {totalGroceries.toLocaleString({ style: 'currency' })}</div>
      </fieldset>

      <div>Total: {(grandTotal).toLocaleString({ style: 'currency' })}</div>
    </Form>
  );
}

export default Apartment;