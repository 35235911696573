import React, { Component } from 'react';
import 'App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Rent from './Rent';
import Buy from './Buy';
import BaseParameters from './BaseParameters';
import Instructions from '../Instructions';

class RentBuy extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false, totalRent: 0, totalBuy: 0,
      years: 0, lifeExpectancy: 90, age: 30,
      rentIncrease: 5, carpetCleaning: 500, removalCosts: 500, rent: 0,
      interest: 3.5, stampDuty: 1500, conveyancingCost: 1500, buildingPestInspection: 1000, purchasePrice: 0
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleYearsChange = this.handleYearsChange.bind(this);
    this.state.years = this.state.lifeExpectancy - this.state.age;
  }

  componentDidMount() {
    this.props.setTitle("Rent Or Buy (Principle Place of Residence)");
    this.calculate();
  }
  componentWillUnmount() {
    this.props.setTitle();
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  handleYearsChange(event) {
    this.setState({ years: parseFloat(event.target.value) }, this.calculate);
  }

  handleRentChange = (event) => {
    this.setState({ rent: parseFloat(event.target.value) }, this.calculate);
  }

  handleRentIncrease = (event) => {
    this.setState({ rentIncrease: parseFloat(event.target.value) }, this.calculate);
  }

  handleLifeExpectancy = (event) => {
    this.setState({ lifeExpectancy: parseFloat(event.target.value) }, this.calculateYears)
  }

  handleAgeChange = (event) => {
    this.setState({ age: parseFloat(event.target.value) }, this.calculateYears)
  }

  calculateYears = () => {
    this.setState({ years: this.state.lifeExpectancy - this.state.age }, this.calculate)
  }

  calculate = () => {
    let newRentTotal = this.state.rent * 52 * Math.pow( this.state.years, (1 + this.state.rentIncrease / 100));
    let newBuyTotal = this.state.purchasePrice + parseFloat(this.state.stampDuty) + parseFloat(this.state.conveyancingCost) + parseFloat(this.state.buildingPestInspection);
    this.setState({ totalRent: newRentTotal });
    this.setState({ totalBuy: newBuyTotal });
  }

  render() {
    const { years, age, lifeExpectancy, rent, rentIncrease } = this.state;

    return (

      <Container className="Calc">
        <h1 className="calc-header">Rent a house or Buy one</h1>
        <Instructions handleCloseModal={this.handleCloseModal} handleOpenModal={this.handleOpenModal} showModal={this.state.showModal} />
        <BaseParameters years={years} age={age} lifeExpectancy={lifeExpectancy} changeLength={this.handleYearsChange} changeAge={this.handleAgeChange} changeLifeExpectancy={this.handleLifeExpectancy} />
        <Row>
          <Col sm={6}>
            <Rent years={years} rent={rent} rentIncrease={rentIncrease} total={this.state.totalRent} onRentChange={this.handleRentChange} onRentIncrease={this.handleRentIncrease} />
          </Col>
          <Col sm={6}>
            <Buy />
          </Col>
        </Row>
      </Container>

    );
  }
}

export default RentBuy;
