import React, { useEffect, useState } from 'react';
import 'App.css';
import Container from 'react-bootstrap/Container';
import InstructionsModal from '../InstructionsModal';
import Row from 'react-bootstrap/Row';
import { Route, Routes, Link } from 'react-router-dom';
import BudgetCategory from './BudgetCategory';
import CheckBoxLabelGroup from 'CheckBoxLabelGroup';

function UnitCostAlternatives(props) {

  const setTitle = props.setTitle
  const [showModal, setShowModal] = useState(false);
  const [planningMode, setPlanningMode] = useState(false);

  const [UnitCostCategories, setUnitCostCategories] = useState([]);

  useEffect(() => {
    fetch('/unit-costs-data/UnitCostCategories.json')
      .then(response => response.json())
      .then(json => setUnitCostCategories(json))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    setTitle("Budget discretionary spending with unit costs.");
  }, [setTitle]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePlanningModeChange = (event) => {
    setPlanningMode(event.target.checked);
  }

  return (
    <Container className='unit-costs-apps'>
      <h1 className="calc-header">Unit Cost Budgeting</h1>
      <InstructionsModal handleCloseModal={handleCloseModal} handleOpenModal={handleOpenModal} showModal={showModal}>
        <p>When you are planning your monthlu budgets, it can be tricky to estimate the totals each month.
          So you can choose how many you buy in a set period and we'll calculate it for you!</p>
      </InstructionsModal>
      <Row>
        <ul className='horizontal-submenu'>
          {UnitCostCategories.map((category) => (
            <li key={category.name}><Link to={category.name} state={{ title: "value" }}>{category.title}</Link></li>
          ))}
        </ul>
      </Row>
      <Row>
        <CheckBoxLabelGroup
          label="Planning Mode (No Maximum or Duration)"
          type="checkbox"
          controlId="planning-mode"
          title="If you're just planning costs, use planning mode and don't worry about durations or maximum amounts."
          onChange={handlePlanningModeChange}
          value={planningMode}
        />
      </Row>
      <Row>
        <Routes>
          {UnitCostCategories.map((category) => (
            <Route key={category.name} path={category.name} element={<BudgetCategory setTitle={props.setTitle} dataPath={category.name} title={category.title} planningMode={planningMode} />} />
          ))}
        </Routes>
      </Row>
    </Container>
  );
};

export default UnitCostAlternatives;
