import React from 'react';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function InputLabelGroup(props) {

  return (
    <Form.Group controlId={props.controlId}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={props.title ? <Tooltip id={`tooltip-bottom`}>{props.title}</Tooltip> : <></>}
        trigger={'focus'}
      >
        <Form.Check
          type="switch"
          id={props.controlId + 'switch'}
          label={props.label}
          value={props.value} onChange={props.onChange}
          data-toggle="tooltip" title={props.title}
        />
      </OverlayTrigger>

    </Form.Group>
  )
}

export default InputLabelGroup;