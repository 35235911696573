import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

class Instructions extends React.Component {
    render() {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <Button href="#" onClick={this.props.handleOpenModal}>What is this?</Button>
                </p>
                <Modal
                    size={'lg'}
                    show={this.props.showModal}
                    onHide={this.props.handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Welcome!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="introduction">
                        <p>Buying a house is the most important financial decision in one's life. One often spends a reasonable amount of time and money looking for the best house they can find given their budget. Media and economics often raise the question of whether one should buy or rent a place. Contrary to what you may have thought of as a straight forward question, this one is actually quite complex. It involves numerous estimations and assumptions, for example, on the annual rent increase, how often one has to move if renting. There are a number of hidden costs that one may not be thinking about until much later in the process of buying a house.</p>
                        <p>This website aims to provide you with a complete set of assumptions and estimations on a list of costs that you should consider when making the decision. To get a better perspective, we encourage you to think of different scenarios and play with the estimations and assumptions to see how those changes will affect the outcome.</p>
                        <p>A set of default values are provided to help you get started.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.handleCloseModal}>OK, show me.</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Instructions;