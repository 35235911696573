import React, { useEffect, useState } from 'react';
import 'App.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { BudgetTable } from './BudgetTable';

function BudgetCategory(props) {

  const [spend, setSpend] = useState(50)
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch('/unit-costs-data/' + props.dataPath + '.json')
      .then(response => response.json())
      .then(json => setData(json))
      .catch(error => console.error(error));
  }, [props.dataPath]);

  const handleSpendChange = (event) => {
    setSpend(event.target.value);
  };

  return (
    <Container className="Calc">
      <h3 className="calc-header">{props.title}</h3>
      {!props.planningMode &&
        <Row className='justify-content-center'>
          <Form.Group controlId={props.controlId} as={Row}>
            <Form.Label column sm={5}>Amount you will budget each month for {props.title}</Form.Label>
            <Col>
              <Form.Control type="number" min={1} value={spend} onChange={handleSpendChange} />
            </Col>
          </Form.Group>
        </Row>}
      <BudgetTable data={data} spend={spend} planningMode={props.planningMode} />
    </Container>
  );
};

export default BudgetCategory;
