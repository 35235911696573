import React from 'react';
import InputLabelGroup from 'InputLabelGroup';
import SelectLabelGroup from 'SelectLabelGroup';
import Form from 'react-bootstrap/Form';

class Buy extends React.Component {

    render() {
        return(
            <Form>
              <InputLabelGroup label="Price of the house" type="text" controlId="housePrice" />
              <InputLabelGroup label="Average annual rate of house price increase:" type="text" controlId="houseIncrease" defaultValue="5%" />
              <SelectLabelGroup label="Are you a first home owner?" controlId="firstHomeOwner">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </SelectLabelGroup>
              <InputLabelGroup label="Stamp duty cost:" type="text" controlId="stampDuty" />
              <InputLabelGroup label="Convenancing Cost:" type="text" controlId="convenancing" defaultValue="$1000" />
              <InputLabelGroup label="Building and Pest Inspection:" type="text" controlId="buildingInspection" defaultValue="$1000" />
              <InputLabelGroup label="Current interest rate of home loan:" type="text" controlId="interestRate" defaultValue="4%" />
              {/* <input type="submit" defaultValue="Calculate" name="submit" onClick="" /> */}
            </Form>
            
        )
    }
}

export default Buy;