import React, { useState, useEffect } from 'react';
import 'App.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Apartment from './Apartment';
import Hotel from './Hotel';
import BaseParameters from './BaseParameters';
import InstructionsModal from '../InstructionsModal';
import GrandTotal from 'GrandTotal';
import Accordion from 'react-bootstrap/Accordion';

function ApartmentHotel(props) {

  const setTitle = props.setTitle
  const [hBest, setHBest] = useState(false);
  const [apBest, setApBest] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hTotal, setHTotal] = useState(0);
  const [aTotal, setATotal] = useState(0);
  const [nights, setNights] = useState(5);
  const [numberOfPeople, setNumberOfPeople] = useState(2);

  useEffect(() => {
    setTitle("Apartment or Hotel");
  }, [setTitle]);

  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleNightsChange = (e) => {
    setNights(e.target.value);
    calculate();
  }

  const handlenumberOfPeopleChange = (event) => {
    setNumberOfPeople(event.target.value)
  }

  const handleHotelGrandTotalChange = (value) => {
    setHTotal(value)
    calculate()
  }

  const handleApartmentGrandTotalChange = (value) => {
    setATotal(value)
    calculate()
  }

  const calculate = () => {
    setHBest(hTotal < aTotal);
    setApBest(aTotal < hTotal);
  }


  return (
    <div className="app-wrapper">
      <Container className="Calc">
        <h1 className="calc-header">Stay in Apartment or Hotel</h1>
        <InstructionsModal handleCloseModal={handleCloseModal} handleOpenModal={handleOpenModal} showModal={showModal}>
          <p>In this scenario an 'Apartment' is a serviced apartment, apartment-hotel, cabin, or other type of holiday accomodation where you have personal access to a kitchen.</p>
          <p>Sometimes you may even have access to a DVD player. These types of extras mean you can save costs on food or entertainment. However they are often more expensive per night than 'typical' hotels.</p>
          <p>A "Hotel" is a standard hotel with just a bed, TV, kettle, mini fridge, but it's not usually comfortable to eat in, except maybe takeaway.</p>
        </InstructionsModal>
        <BaseParameters nights={nights} adults={numberOfPeople} changeLength={handleNightsChange} changeAdults={handlenumberOfPeopleChange} />
        <Row>
          <Col sm={6} className={`d-flex flex-column grand-total-narrow ${apBest === true ? 'best' : ''} choices-app`}>
            <h2>Apartment</h2>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header as="h4">Considerations...</Accordion.Header>
                <Accordion.Body>
                  <p>A serviced apartment might be a good choice even if you want to go to restaurants but you want more space. For example you should get a large dining table, so you could meet friends on holiday and invite them in for boardgames and tea and cake. Or hopefully you get a couch and nice TV so you can relax with a movie after a day trip.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Apartment nights={nights}
              onGrandTotalChange={handleApartmentGrandTotalChange} />
            <div className='footer'><GrandTotal total={aTotal} best={apBest} /></div>
          </Col>
          <Col sm={6} className={`d-flex flex-column grand-total-narrow ${hBest === true ? 'best' : ''}`}>
            <h2>Hotel</h2>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header as="h4">Considerations...</Accordion.Header>
                <Accordion.Body>
                  <p>Even if a hotel is more expensive, you still might prefer it if you want luxury and you specifically know you won't be cooking on holiday anyway. Certainly hotels more common so are easier to book</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Hotel
              nights={nights}
              numberOfPeople={numberOfPeople}
              onGrandTotalChange={handleHotelGrandTotalChange}
            />
            <div className='footer'><GrandTotal total={hTotal} best={hBest} /></div>
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default ApartmentHotel;
