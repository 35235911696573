import React from 'react';
import InputLabelGroup from 'InputLabelGroup';
import SelectLabelGroup from 'SelectLabelGroup';
import Form from 'react-bootstrap/Form';

class Rent extends React.Component {

    render() {
        return(
            <Form>
              <InputLabelGroup label="Rent per week" type="text" controlId="rentAmount" onChange={this.props.onRentChange} value={this.props.rent}/>
              <InputLabelGroup label="Annual rate of rent increase" type="text" controlId="increaseRate" value={this.props.rentIncrease} onChange={this.props.onRentIncrease} />
              {/* <InputLabelGroup label="Carpet cleaning cost" type="text" controlId="carpetCleaning" value={this.state.carpetCleaning} /> */}
              {/* <InputLabelGroup label="Removal cost" type="text" controlId="movalCost" value={this.state.removalCosts} /> */}
              <SelectLabelGroup label="How often do you move to a new place?" controlId="movingFreq">
                  <option value="1">Once a year</option>
                  <option value="3">Every three years</option>
                  <option value="5">Every five years</option>
                  <option value="10">Every 10 years</option>
              </SelectLabelGroup>
              <div>Total: {this.props.total.toLocaleString({ style: 'currency'})}</div>  
            </Form>
            
        );
    }
}

export default Rent;