import React from 'react';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

class Instructions extends React.Component {
    render() {
        return (
            <React.Fragment>
                <p style={{ textAlign: 'center' }}>
                    <Button href="#" onClick={this.props.handleOpenModal}>How to use?</Button>
                </p>
                <Modal
                    size={'lg'}
                    show={this.props.showModal}
                    onHide={this.props.handleCloseModal}>
                    <Modal.Header>
                        <Modal.Title>Instructions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="introduction">
                        {this.props.children}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.props.handleCloseModal}>OK, thanks.</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Instructions;